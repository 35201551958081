.Strengths-Section-Mobile {
  height: 500px;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
  margin-top: -50px;
}

.Strengths-Section-Mobile-Header {
  background: rgb(235, 235, 235);
  background: linear-gradient(90deg, rgba(252, 251, 251, 1) 0%, rgba(235, 235, 235, 1) 100%);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  height: 50px;
  width: 230px;
  margin-left: 15%;
  z-index: 5;
  position: relative;
  font-size: 1.1rem;
  color: #081231;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Strengths-Section-Mobile-Header:hover {
  cursor: default;
}

.Strengths-Section-Mobile-Header-Text {
  margin-top: auto;
  margin-bottom: auto;
}

.Strengths-Section-Mobile-Header-Symbol {
  margin-top: 12px;
  background-image: url('./../../images/strength.svg');
  width: 26px;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 20px;
}

.Strengths-Section-Mobile-Body {
  background: rgb(235, 235, 235);
  background: linear-gradient(90deg, rgba(252, 251, 251, 1) 0%, rgba(235, 235, 235, 1) 100%);
  color: #08162b;
  margin-top: -25px;
  /* needs to be negative so that it sits atop of the other div */
  background: rgb(235, 235, 235);
  background: linear-gradient(90deg, rgba(252, 251, 251, 1) 0%, rgba(235, 235, 235, 1) 100%);
  z-index: 1;
  padding-bottom: 30px;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
}

.Strengths-Mobile {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.Strengths-Mobile-Top {
  height: 15%;
  display: flex;
  margin-left: 9%;
  margin-bottom: 10px;
}

.Strengths-Mobile-Top-Dot {
  border-radius: 50%;
  border: 3px solid #0c1c35;
  height: 12px;
  width: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.Strengths-Mobile-Top-Text {
  margin-left: 3%;
  font-size: 1.5rem;
  font-family: Noto Serif, serif;
  margin-top: auto;
  margin-bottom: auto;
}

.Strengths-Mobile-Bottom {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0px 4%;
}

.Strengths-Mobile-Strengths-Section {
  padding: 3px;
  display: flex;
  flex-direction: column;
}

.Strengths-Section-Mobile-Symbol {
  border-radius: 50%;
  border: 6px solid #0c1c35;
  height: 82px;
  width: 82px;
  margin-left: auto;
  margin-right: auto;
}

.Skills-Section-Mobile-Symbol {
  border-radius: 50%;
  border: 6px solid #0c1c35;
  height: 55px;
  width: 55px;
  margin-left: auto;
  margin-right: auto;
}

.Strengths-Section-Mobile-Symbol-Wrapper {
  width: 60px;
  /*change size to screen width to some degree */
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  transition: filter 0.3s;
}

.Strengths-Section-Mobile-Symbol-Wrapper:hover {
  filter: brightness(1.2);
}

.Strengths-Section-Mobile-Symbol-VisualDesign {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/visualdesign.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Strengths-Section-Mobile-Symbol-FrontEnd {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/frontend.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Strengths-Section-Mobile-Symbol-Communication {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/communication.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Wrapper {
  width: 45px;
  /*change size to screen width to some degree */
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  transition: filter 0.3s;
}

.Skills-Section-Mobile-Symbol-Wrapper:hover {
  filter: brightness(1.2);
}

.Skills-Section-Mobile-Symbol-React {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/react.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Photoshop {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/photoshop.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Javascript {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/javascript.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Java {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/java.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Git {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/git.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Node {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/node.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-AdobeXD {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/xd.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Skills-Section-Mobile-Symbol-Figma {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  background-image: url('./../../images/figma.svg');
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.Strengths-Section-Mobile-Text {
  font-size: 0.95rem;
  font-weight: 600;
  margin-top: 10px;
}

.Skills-Mobile-Top-Text {
  margin-left: 3%;
  font-size: 1.5rem;
  font-family: Noto Serif, serif;
  margin-top: auto;
  margin-bottom: auto;
}

.Skills-Mobile-Header-Wrapper {
  height: 40px;
  display: flex;
  flex-direction: row;
}

.Skills-Mobile-Top-Dot {
  border-radius: 50%;
  border: 3px solid #0c1c35;
  height: 12px;
  width: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.Skills-Mobile {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.Skills-Mobile-Top {
  height: 15%;
  display: flex;
  margin-left: 7%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Skills-Mobile-Bottom {
  display: flex;
  flex-direction: column;
}

.Skills-Mobile-Right-Row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
    margin-bottom:15px;
}

.Skills-Section-Mobile-Text {
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 10px;
}
