.Header-Section {
  color: white;
  font-family: Noto Serif, serif;
  position: relative;
  min-height: 560px;
}

.Header-Section-Left {
  display: inline-block;
  width: 90%;
}

.Header-Section-Right {
  width: 10%;
  background-color: rgba(235, 235, 235, 1);
  position: absolute;
  display: inline-block;
  height: 100%;
}

.Header-Section-Line {
  border-left: 2px solid white;
  margin-top: 85px;
  height: 72%;
  position: relative;
  left: -15%;
}

.Header-Section-Wrapper {
  display: flex;
  flex-direction: row;
}

.Header-Text-Section {
  margin-left: 3%;
  width: 75%;
}

.Header-Container {
  padding: 80px 90px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}

.Header-Bar-Top {
  display: flex;
  flex-direction: row;
}

.Header-Text-Section-Menu {
  display: flex;
  flex-direction: row;
  margin-top: 23px;
  font-size: 1.25rem;
  text-decoration: none;
  transition: text-decoration 1s;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.Header-Text-Section-Menu-Left{
  text-decoration:none;
  color:inherit;
}

.Header-Text-Section-Menu-Right{
  text-decoration:none;
  color:inherit;
}


.Header-Text-Section-Menu-Left:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Header-Text-Section-Menu-Right {
  margin-left: 5%;
}

.Header-Text-Section-Menu-Right:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Header-Bar-Top-Dot {
  flex: 1;
}

.Dot-Image {
  border-radius: 50%;
  border: 5px solid white;
  height: 12px;
  width: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

/*currently disabled, may add back if i want to translate the page into french*/
.Header-Bar-Top-Language-Wrapper {
  flex: 1;
  opacity: 0;
}

.Header-Bar-Top-Language {
  background-color: rgba(255, 255, 255, 0.1);
  width: 150px;
  margin-left: auto;
  padding: 7px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Header-Text-Section-Line {
  background-color: #8ca8cc;
  height: 3px;
  outline: none;
  border: none;
  margin-top: 23px;
  width: 48%;
  margin-left: 0px;
  align: left;
}

.Language-Text {
  Font-Family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.Language-Symbol {
  background-image: url('./../../images/language.svg');
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.Header-Text-Section-Title {
  font-size: 3.7rem;
  text-align: left;
  font-weight: 200;
  color: #dae8fd;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
  opacity: 1;
  transition: opacity 0.5s;
}

.Header-Text-Section-Title:hover {
  opacity: 0.8;
}

.Header-Text-Section-AboutMe {
  margin-top: 35px;
  text-align: left;
  font-size: 0.88rem;
  width: 88%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 24px;
}

.Header-Text-Section-AboutMe:hover {
  cursor: default;
}

.Header-Photo-Section {
  width: 50%;
}

.Header-Photo-Circle {
  width: 20.5vw;
  height: 100%;
  padding-left: 60px;
  border-radius: 50%;
  background-color: rgba(6, 37, 80, 0.6);
}

.Header-Photo-Me {
  width:75%;
  height:auto;
  min-width:320px;
 
  transition: opacity 0.5s;
}

.Header-Photo-Me:hover {
  opacity: 0.85;
}