.Contact-Section {
  min-height: 500px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  margin-top: -25px;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.Contact-Section-Left {
  width: 120px;
  background: rgba(252, 251, 251, 1);
}

.Contact-Section-Line {
  border-right: 2px solid white;
  margin-top: 85px;
  height: 75%;
  position: relative;
  right: -15%;
}

.Contact-Section-Right {
  flex: 1;
}

.Contact-Section-Wrapper {
  display: flex;
  flex-direction: row;
  height: 500px;
  margin-top: -45px;
}

.Contact-Section-Body {
  margin-left: 10%;
  margin-right: 3%;
  margin-top: 80px;
  height: 80%;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
}

.Contact-Section-Row {
  flex: 1;
  transition: filter 0.3s;
}

.Contact-Section-Row:hover {
  filter: sepia(160%);
  cursor: pointer;
}

.Contact-Section-Row-Final {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.Contact-Section-Row-Wrapper {
  height: 70%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  color: inherit;
  text-decoration: none;
}

.Contact-Section-Row-Wrapper {
  display: flex;
  flex-direction: row;
}

.Contact-Section-Row-Wrapper-Final-Left {
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  color: inherit;
  text-decoration: none;
}

.Contact-Section-Row-Wrapper-Final-Left:hover {
  filter: sepia(160%);
  cursor: pointer;
}

.Contact-Section-Row-Wrapper-Final-Right {
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  margin-top: 5px;
}

.Contact-Section-Row-Wrapper-Final-Right:hover {
  cursor: default;
}

.Contact-Row-Left-Final {
  flex: 1;
  text-align: right;
  margin-right: 20px;
}

.Contact-Row-Right-Final-Left {
  margin-left: 6%;
}

.Contact-Row-Right-Final-Right {
  width: 18%;
}

.Contact-Row-Left {
  font-size: 1.3rem;
  text-align: right;
}

.Contact-Row-Right {
  margin-left: 3%;
}

.Contact-Row-Title {
  font-weight: 600;
}

.Contact-Row-Link {
  font-weight: 100;
}

.Flaticon-Title {
  font-weight: 600;
  font-size: 1.05rem;
}

.Flaticon-Link {
  font-size: 0.9rem;
  font-weight: 100;
}

.Contact-Section-Header-Container {
  width: 72%;
  margin-left: auto;
  margin-right: auto;
}

.Contact-Section-Header {
  background: #0e2039;
  background: linear-gradient(90deg, #0e2039 0%, #0c1d34 100%);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  height: 65px;
  width: 320px;
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.Contact-Section-Header-Text {
  margin-top: auto;
  margin-bottom: auto;
}

.Contact-Section-Header-Symbol {
  margin-top: 17px;
  background-image: url('./../../images/email.svg');
  width: 32px;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 20px;
}

.Contact-Symbol-Email {
  background-image: url('./../../images/gmail.svg');
  width: 51px;
  height: 51px;
  background-size: 51px;
  background-repeat: no-repeat;
}

.Contact-Symbol-Github {
  background-image: url('./../../images/github.svg');
  width: 51px;
  height: 51px;
  background-size: 51px;
  background-repeat: no-repeat;
}

.Contact-Symbol-LinkedIn {
  background-image: url('./../../images/linkedin.svg');
  width: 51px;
  height: 51px;
  background-size: 51px;
  background-repeat: no-repeat;
}

.Contact-Symbol-Dribbble {
  background-image: url('./../../images/dribbble.svg');
  width: 51px;
  height: 51px;
  background-size: 51px;
  background-repeat: no-repeat;
}

.Contact-Symbol-Flaticon {
  background-image: url('./../../images/flaticon.svg');
  width: 51px;
  height: 51px;
  background-size: 51px;
  background-repeat: no-repeat;
}