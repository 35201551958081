.Work-Section {
  color: #08162b;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
}

@media only screen and (max-width: 500px) {
  .Work-Section-Body {
    font-size: 0.8rem;
    border: 40px 0px;
  }
}

.Work-Section-Header-Container {
  width: 72%;
  margin-left: auto;
  margin-right: auto;
}

.Work-Section-Header {
  background: rgb(235, 235, 235);
  background: linear-gradient(90deg, rgba(252, 251, 251, 1) 0%, rgba(235, 235, 235, 1) 100%);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  height: 65px;
  width: 320px;
  position: relative;
  font-size: 1.5rem;
  color: #081231;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.Work-Section-Header-Text {
  margin-top: auto;
  margin-bottom: auto;
}

.Work-Section-Header-Symbol {
  margin-top: 17px;
  background-image: url('./../images/work.svg');
  width: 36px;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 20px;
}

@media only screen and (max-width: 500px) {
  .Work-Section-Header {
    background: rgb(235, 235, 235);
    background: linear-gradient(90deg, rgba(252, 251, 251, 1) 0%, rgba(235, 235, 235, 1) 100%);
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
    height: 50px;
    width: 230px;
    margin-left: 4%;
    z-index: 5;
    position: relative;
    font-size: 1.15rem;
    color: #081231;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Work-Section-Header-Text {
    margin-top: auto;
    margin-bottom: auto;
  }

  .Work-Section-Header-Symbol {
    margin-top: 12px;
    background-image: url('./../images/work.svg');
    width: 26px;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: 20px;
  }
}

.Work-Section-Body {
  background: rgb(235, 235, 235);
  background: linear-gradient(90deg, rgba(252, 251, 251, 1) 0%, rgba(235, 235, 235, 1) 100%);
  padding-bottom: 75px;
}

@media only screen and (max-width: 500px) {
  .Work-Section-Body {
    padding-bottom: 35px;
  }
}

.Work-Section-Body2 {
  text-align: left;
  max-width: 1320px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 500px) {
  .Work-Section-Body2 {
    max-width: inherit;
    padding: 0px 30px;
  }
}

.Work-Title {
  padding-top: 75px;
  text-align: left;
  font-size: 2.6rem;
  font-weight: 600;
  font-family: Noto Serif;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

@media only screen and (max-width: 500px) {
  .Work-Title {
    font-size: 1.9rem;
    padding-top: 35px;
  }
}

@media only screen and (max-width: 500px) {
  .WM-Title {
    padding-top: 65px;
  }
}

.Work-Link {
  font-size: 0.9rem;
  margin-top: 6px;
  color: #597cbd;
  font-weight: 600;
}

@media only screen and (max-width: 500px) {
  .Work-Link {
    font-size: 0.85rem;
  }
}

.Work-Link-Text:visited {
  color: #597cbd;
}

.Work-Link-Text:hover {
  color: #799ddf;
}

.Work-Text-Paragraph {
  margin-top: 40px;
}

.Work-Text-Title {
  font-weight: 600;
}

.Photo-Wrapper {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
}

@media only screen and (max-width: 500px) {
  .Photo-Wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
  }
}

.Work-Photo {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  transition: filter 0.5s;

  width:100%;
  height:auto;
}

.Work-Photo:hover {}