.App {}

.a {
  color: inherit;
}

.App-Wrapper {
  background: #10233e;
  background-image: url('./images/background5.svg');
  /* fallback */
  background-image: url('./images/background5.svg'), linear-gradient(#10233e 0%, #0c1c35 100%);
  background-attachment: fixed;
  background-repeat: repeat-y;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  background-size: cover;
}

.App-Wrapper-Mobile {
  background: #10233e;
  background-image: url('./images/background-mobile.png');
  /* fallback */
  background-image: url('./images/background-mobile.png'), linear-gradient(#10233e 0%, #0c1c35 100%);
  background-attachment: fixed;
  background-repeat: repeat-y;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

/*-------- iPads (portrait and landscape) --------*/
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
#ci-hero-11 .widget-wrap , #ci-hero-12 .widget-wrap {

    background-attachment: initial;

}
}

.Divider {
  height: 100px;
}

.Divider-Mobile {
  height: 85px;
}
