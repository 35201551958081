.Contact-Section-Mobile {
  min-height: 480px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  margin-top:-25px;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}



.Contact-Section-Middle {
  flex: 1;
}

.Contact-Section-Mobile-Wrapper{

  margin-top:-45px;
  padding-left:5%;
}

.Contact-Section-Mobile-Body {
  margin-left: 10%;
  margin-right: 3%;
  margin-top:100px;


}

.Contact-Section-Mobile-Row {
  margin-top:40px;
  transition: filter 0.5s;
}

.Contact-Section-Mobile-Row:hover{
  filter: sepia(70%);
  cursor:pointer;
}

.Contact-Section-Mobile-Row-Final {
  margin-top:75px;
  display:flex;
  flex-direction:row;
  margin-left:15%;
  margin-bottom:35px;
  margin-right:8%;

}

.Contact-Section-Mobile-Row-Wrapper {
  height: 70%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  color:inherit;
  text-decoration:none;
  display: flex;
  flex-direction: row;
}



.Contact-Section-Mobile-Row-Wrapper-Final {
  text-align: right;
  flex:1;
  margin-top:5px;
  margin-right:25px;


}

.Contact-Section-Mobile-Row-Wrapper-Final:hover{
  cursor:default;
}




.Contact-Mobile-Row-Right-Final{
  width:18%;
}

.Contact-Mobile-Row-Left {
  font-size: 1.3rem;
  text-align: right;
}

.Contact-Mobile-Row-Right {
  margin-left: 5%;
}

.Contact-Mobile-Row-Title {
  font-weight: 600;
}

.Contact-Mobile-Row-Link {
  font-weight: 100;
}



.Flaticon-Title-Mobile {
  font-weight: 600;
  font-size: 0.85rem;
}

.Flaticon-Link-Mobile {
  font-size: 0.75rem;
  font-weight:300;
}

.Contact-Section-Mobile-Header {
  background: #0e2039;
  background: linear-gradient(90deg, #0e2039 0%, #0c1d34 100%);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.36);
  height: 50px;
  width: 230px;
  margin-left: 15%;
  z-index: 5;
  position: relative;
  font-size: 1.15rem;
  color: white;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Contact-Section-Mobile-Header-Text {
  margin-top: auto;
  margin-bottom: auto;
}

.Contact-Section-Mobile-Header-Symbol {
  margin-top: 12px;
  background-image: url('./../../images/email.svg');
  width: 26px;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left:25px;
}

.Contact-Mobile-Symbol-Email {
  background-image: url('./../../images/gmail.svg');
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-repeat: no-repeat;
}

.Contact-Mobile-Symbol-Github {
  background-image: url('./../../images/github.svg');
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-repeat: no-repeat;
}

.Contact-Mobile-Symbol-LinkedIn {
  background-image: url('./../../images/linkedin.svg');
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-repeat: no-repeat;
}

.Contact-Mobile-Symbol-Dribbble {
  background-image: url('./../../images/dribbble.svg');
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-repeat: no-repeat;
}

.Contact-Mobile-Symbol-Flaticon {
  background-image: url('./../../images/flaticon.svg');
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-repeat: no-repeat;
}
