.Header-Section-Mobile {
  color: white;
  font-family: Noto Serif, serif;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
}

.Header-Section-Mobile-Dot-Wrapper {
  /*border:2px solid black;*/
}

.Header-Section-Mobile-Dot {
  border-radius: 50%;
  border: 3px solid white;
  height: 12px;
  width: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.Header-Section-Mobile-Top {
  /*border:2px solid black;*/
  display: flex;
  flex-direction: row;
}

.Header-Section-Mobile-Bottom {
  /*border:2px solid black;*/
  flex: 1;
}

.Header-Section-Mobile-Top-Left {
  /*border:1px solid red;*/
  flex: 1;
  font-size: 2.5rem;
  text-align: left;
  font-weight: 200;
  color: #dae8fd;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
  opacity: 1;
  transition: opacity 0.5s;
}

.Header-Section-Mobile-Top-Right {
  /*border:1px solid red;*/
  flex: 1;
}

.Header-Section-Mobile-Title {
  margin-top: 20px;
  font-size: 2.2rem;
  line-height: 45px;
  text-align: left;
  font-weight: 200;
  color: #dae8fd;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
  opacity: 1;
  transition: opacity 0.5s;
}

.Header-Section-Mobile-Photo-Me {
  margin-top: 10px;
  opacity: 1;
  transition: opacity 0.5s;

}

.Header-Photo-Me-Mobile{
  width:190px;
  height:auto;
}

.Header-Section-Mobile-Line {
  background-color: #8ca8cc;
  height: 3px;
  outline: none;
  border: none;
  margin-top: 23px;
  width: 90%;
  margin-left: 0px;
  align: left;
}

.Header-Section-Mobile-AboutMe {
  margin-top: 25px;
  text-align: left;
  font-size: 0.76rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding-bottom: 80px;
}

.Header-Section-Mobile-Menu {
  padding-top: 25px;
  color: #fefdf5;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: left;
  text-decoration: none;
  transition: text-decoration 1s;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.Header-Section-Mobile-Menu-Top{
  color:inherit;
  text-decoration:none;
}

.Header-Section-Mobile-Menu-Bottom {
  margin-top: 3%;
  color:inherit;
  text-decoration:none;
}